<template>
    <div id="payment-page">
        <v-card-title class="text-uppercase justify-center justify-lg-start primary--text px-0 font-weight-bold">Payment method</v-card-title>
        <v-row>
            <v-col cols="12" class="col-lg-3">
                <v-row class="pa-0 ma-0 mb-5 mobile-page" align="center" justify="space-between">
                    <v-btn type="button" color="secondary" large block class="font-weight-bold rounded-lg" :outlined="model.payment_type !== 'CC'" rounded >Credit card</v-btn>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="col-lg-6">
                <slot name="receiptName"></slot>
            </v-col>
        </v-row>
        <template>
            <v-row>
                <v-col cols="12" class="col-lg-6 mobile-page">
                    <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize" :class="{'error--text': card.error !== '' || submitForm && card.empty}">Card number*</v-subheader>
                    <div id="card-number" class="stripe-element-style rounded-lg" :class="{'stripe-element-focus':  card.focus && !(card.error !== '' || submitForm && card.empty),
                    'error-message': card.error !== '' || submitForm && card.empty}"></div>
                    <div v-if="card.error !== '' || (submitForm && card.empty)" class="error--text">
                        <span v-if="submitForm && card.empty">Card Number is required</span>
                        <span v-else>{{ card.error }}</span>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="col-lg-6 mobile-page">
                    <v-row class="w-full ma-0 pa-0" align="center" justify="space-between">
                        <div class="w-49">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize" :class="{'error--text': expiry.error !== '' || submitForm && expiry.empty}">expiry date*</v-subheader>
                            <div id="card-expiry" class="stripe-element-style rounded-lg" :class="{'stripe-element-focus':  expiry.focus && !(expiry.error !== '' || submitForm && expiry.empty),
                            'error-message': expiry.error !== '' || submitForm && expiry.empty}"></div>
                            <div v-if="expiry.error !== '' || submitForm && expiry.empty" class="error--text">
                                <span v-if="submitForm && expiry.empty">Expiry Date is required</span>
                                <span v-else>{{ expiry.error }}</span>
                            </div>
                        </div>
                        <div class="w-49">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize" :class="{'error--text': cvc.error !== '' || submitForm && cvc.empty}">CVC*</v-subheader>
                            <div id="card-cvc" class="stripe-element-style rounded-lg" :class="{'stripe-element-focus':  cvc.focus && !(cvc.error !== '' || submitForm && cvc.empty),
                            'error-message': cvc.error !== '' || submitForm && cvc.empty}"></div>
                            <div v-if="cvc.error !== '' || submitForm && cvc.empty" class="error--text">
                                <span v-if="submitForm && cvc.empty">CVC is required</span>
                                <span v-else>{{ cvc.error }}</span>
                            </div>
                        </div>
                    </v-row>
                </v-col>
            </v-row>
        </template>
        <v-row v-if="paymentModel.stripeErr !== ''">
            <v-col cols="12" class="col-lg-6">
                <div class="error--text">
                    {{ paymentModel.stripeErr }}
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from "vuex";

import { Input, Dropdown } from '@/components';

export default {
    name: 'Payment',
    props:["valid", "submitForm", "stripe"],
    emits: ["updateRefs"],
    components: {
        Input,
        Dropdown,
    },
    data() {
        return {
            monthLists: [],
            yearLists: [],
            credit_card: {},
            card: {
                error: '',
                focus: false,
                complete: false,
                empty: true
            },
            expiry: {
                error: '',
                focus: false,
                complete: false,
                empty: true
            },
            cvc: {
                error: '',
                focus: false,
                complete: false,
                empty: true
            },
            style: {
                style: {
                    base: {
                        fontFamily: 'Mont',
                        '::placeholder': {
                            color: '#00000061',
                        },
                        fontSize: '16px',
                        lineHeight: '20px'
                    },
                        invalid: {
                        color: '#FF0000',
                    },
                }
            }
        };
    },
    computed: {
        ...mapGetters(["paymentModel"]),

        model: {
            get() {
                return this.$store.getters["model"];
            },
            set(newValue) {
                return this.$store.commit("updateModel", newValue);
            },
        },
    },
    created() {
        this.nameUpdate = _.debounce(this.nameUpdate, 250);
    },
    mounted() {
        this.$emit('updateRefs', this.$refs);
        setTimeout(() => {
            this.setUpStripe();
        });
    },
    methods: {
        nameUpdate() {
            this.updateModel();
        },
        updateModel() {
            if (this.card.complete && this.expiry.complete && this.cvc.complete) {
                const customData = {
                    cardNumber: this.credit_card.cardNumber,
                    cardValidate: true,
                    stripeErr: ''
                }
                this.$store.commit('updatePaymentModel',customData);
            } else {
                this.$store.commit('updatePaymentModel',{stripeErr: '', cardValidate: false});
            }
        },
        setUpStripe() {
            if (window.Stripe === undefined) {
                alert('Stripe V3 library not loaded!');
            } else {
                const elements = this.stripe.elements({
                    fonts:[
                        {
                            family: 'Mont',
                            src: 'url(/fonts/Mont/Mont-Regular.woff) format(woff)'
                        }
                    ]
                });
                this.credit_card.cardCvc = elements.create('cardCvc', this.style);
                this.credit_card.cardExpiry = elements.create('cardExpiry', this.style);
                this.credit_card.cardNumber = elements.create('cardNumber', {...this.style, ...{ placeholder: 'Card Number' }});
                this.credit_card.cardNumber.mount('#card-number');
                this.credit_card.cardCvc.mount('#card-cvc');
                this.credit_card.cardExpiry.mount('#card-expiry');
            }
            this.listenForErrors();
        },
        listenForErrors() {
            this.cardNumEvent();
            this.cardExpiryEvent();
            this.cardCvcEvent();
        },
        cardNumEvent() {
            const self = this;
           this.credit_card.cardNumber.addEventListener('change', function (event) {
                self.card.error = event.error ? event.error.message : '';
                self.card.complete = event.complete;
                self.card.empty = event.empty;
                self.updateModel();
            });
           this.credit_card.cardNumber.addEventListener('focus', function (event) {
                self.card.focus = true;
            });
           this.credit_card.cardNumber.addEventListener('blur', function (event) {
                self.card.focus = false;
            });
        },
        cardExpiryEvent() {
            const self = this;
           this.credit_card.cardExpiry.addEventListener('change', function (event) {
                self.expiry.error = event.error ? event.error.message : '';
                self.expiry.complete = event.complete;
                self.expiry.empty = event.empty;
                self.updateModel();
            });
           this.credit_card.cardExpiry.addEventListener('focus', function (event) {
                self.expiry.focus = true;
            });
           this.credit_card.cardExpiry.addEventListener('blur', function (event) {
                self.expiry.focus = false;
            });
        },
        cardCvcEvent() {
            const self = this;
           this.credit_card.cardCvc.addEventListener('change', function (event) {
                self.cvc.error = event.error ? event.error.message : '';
                self.cvc.complete = event.complete;
                self.cvc.empty = event.empty;
                self.updateModel();
            });
           this.credit_card.cardCvc.addEventListener('focus', function (event) {
                self.cvc.focus = true;
            });
           this.credit_card.cardCvc.addEventListener('blur', function (event) {
                self.cvc.focus = false;
            });
        }
    }
};
</script>

<style scoped lang="scss">
.stripe-element-style {
    border: thin solid #a1a1a1 !important;
    height: 55px;
    padding: 16px;
}

.stripe-element-style:hover, .stripe-element-focus {
  border-width: 2px !important;
  border-color: var(--v-primary-base) !important;
}

.error-message, .error-message:hover {
  border: 2px solid  var(--v-danger-base) !important;
}
</style>